import { Component, inject } from '@angular/core';
import { ActivatedRoute, Router, UrlSegment } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SharedService } from 'src/app/services/shared.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-bottom-menu',
  templateUrl: './bottom-menu.component.html',
  styleUrls: ['./bottom-menu.component.scss'],
})
export class BottomMenuComponent {
  modules = environment.settings.modules;
  showActive = environment.settings.showActiveDotInBottomMenu;
  cartDetails: any;
  cartCount: number;
  to: string = 'home';
  moreMenu = inject(NgbModal);
  moreButtonHidden: boolean = true;

  constructor(
    private router: Router,
    private sharedService: SharedService,
    private activatedRoute: ActivatedRoute,
  ) {}

  async ngOnInit() {
    this.activatedRoute.url.subscribe((url: UrlSegment[]) => {
      this.to = url.length > 0 ? url[0].path : this.router.url.split('/')[1];
    });
    if (this.modules.cart) {
      this.sharedService.cartCount.subscribe((count) => {
        this.cartCount = count;
      });
    }
  }

  is(
    to:
      | 'home'
      | 'my-feed'
      | 'rewards'
      | 'my-wallet'
      | 'calendar'
      | 'settings'
      | 'appreciate'
      | 'point-history'
      | 'my-cart'
      | 'feeds'
      | 'more',
  ) {
    return (
      (to === 'my-wallet' && to === this.router.url.split('/')[2]) ||
      (to === 'appreciate' && to === this.router.url.split('/')[1]) ||
      this.to === to
    );
  }

  selectedClass(
    to:
      | 'home'
      | 'my-feed'
      | 'rewards'
      | 'my-wallet'
      | 'calendar'
      | 'settings'
      | 'appreciate'
      | 'point-history'
      | 'my-cart'
      | 'feeds'
      | 'more',
  ) {
    return this.is(to) ? 'selected' : '';
  }

  fontClass(to: string) {
    return this.to === to ? 'font-selected' : 'font-unselected';
  }
  onClickTopMenu(
    menu:
      | 'home'
      | 'my-feed'
      | 'rewards'
      | 'my-wallet'
      | 'calendar'
      | 'settings'
      | 'appreciate'
      | 'point-history'
      | 'my-cart'
      | 'feeds'
      | 'more',
  ) {
    sessionStorage.setItem('selected-topMenu', menu);
    this.to = menu;
  }

  openModal(content) {
    this.moreMenu.open(content, {
      windowClass: 'modal-bottom-right',
      backdrop: true,
    });
  }
}
