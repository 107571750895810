<section class="bottom--menu-section">
  <div class="menu--list">
    <div
      class="d-flex justify-content-around align-items-center flex-row side--menu"
    >
      <div
        *ngIf="modules.home"
        class="d-flex flex-column align-items-center side--menu-item"
        [ngClass]="is('home') ? 'selected' : ''"
        [routerLink]="['/home']"
        (click)="onClickTopMenu('home')"
      >
        <span class="icon icon--home" [ngClass]="selectedClass('home')"></span>
        <span *ngIf="!is('home')" [ngClass]="fontClass('home')">
          {{ "common.home" | translate }}
        </span>
        <span *ngIf="is('home') && !showActive" [ngClass]="fontClass('home')">
          {{ "common.home" | translate }}
        </span>
        <div *ngIf="is('home') && showActive" class="active--dot"></div>
      </div>
      <div
        *ngIf="modules.appreciate"
        class="d-flex flex-column align-items-center side--menu-item"
        [ngClass]="is('appreciate') ? 'selected' : ''"
        [routerLink]="['/appreciate']"
        (click)="onClickTopMenu('appreciate')"
      >
        <span
          class="icon icon--appreciate"
          [ngClass]="selectedClass('appreciate')"
        ></span>
        <span *ngIf="!is('appreciate')" [ngClass]="fontClass('appreciate')">
          {{ "common.appreciate" | translate }}
        </span>
        <span
          *ngIf="is('appreciate') && !showActive"
          [ngClass]="fontClass('appreciate')"
        >
          {{ "common.appreciate" | translate }}
        </span>
        <div *ngIf="is('appreciate') && showActive" class="active--dot"></div>
      </div>
      <div
        *ngIf="modules.myWallet"
        class="d-flex flex-column align-items-center side--menu-item"
        [ngClass]="is('my-wallet') ? 'selected' : ''"
        [routerLink]="['/rewards/my-wallet']"
        (click)="onClickTopMenu('my-wallet')"
      >
        <span
          class="icon icon--my-wallet"
          [ngClass]="selectedClass('my-wallet')"
        ></span>
        <span *ngIf="!is('rewards')" [ngClass]="fontClass('my-wallet')">
          {{ "common.myWallet" | translate }}
        </span>
        <div *ngIf="is('rewards')" class="active--dot"></div>
      </div>
      <div
        *ngIf="modules.rewards"
        class="d-flex flex-column align-items-center side--menu-item"
        [ngClass]="is('rewards') ? 'selected' : ''"
        [routerLink]="['/rewards']"
        (click)="onClickTopMenu('rewards')"
      >
        <span
          class="icon icon--rewards"
          [ngClass]="selectedClass('rewards')"
        ></span>
        <span *ngIf="!is('rewards')" [ngClass]="fontClass('rewards')">
          {{ "common.reward.n" | translate }}
        </span>
        <span
          *ngIf="is('rewards') && !showActive"
          [ngClass]="fontClass('rewards')"
        >
          {{ "common.reward.n" | translate }}
        </span>
        <div *ngIf="is('rewards') && showActive" class="active--dot"></div>
      </div>
      <div
        *ngIf="modules.pointsHistory"
        class="d-flex flex-column align-items-center side--menu-item"
        [ngClass]="is('point-history') ? 'selected' : ''"
        [routerLink]="['/point-history']"
        [queryParams]="{ point_type: 'all', point_category: 'all' }"
        (click)="onClickTopMenu('point-history')"
      >
        <span
          class="icon icon--point-history"
          [ngClass]="selectedClass('point-history')"
        ></span>
        <span
          *ngIf="!is('point-history')"
          [ngClass]="fontClass('point-history')"
        >
          {{ "common.pointsHistory" | translate }}
        </span>
        <span
          *ngIf="is('point-history') && !showActive"
          [ngClass]="fontClass('point-history')"
        >
          {{ "common.pointsHistory" | translate }}
        </span>
        <div
          *ngIf="is('point-history') && showActive"
          class="active--dot"
        ></div>
      </div>
      <!-- <div
        *ngIf="modules.cart"
        class="d-flex flex-column align-items-center side--menu-item"
        [ngClass]="is('my-cart') ? 'selected' : ''"
        [routerLink]="['/my-cart']"
        (click)="onClickTopMenu('my-cart')"
      >
        <span class="cart-counter" *ngIf="cartCount > 0">{{ cartCount }}</span>
        <span
          class="icon icon--my-cart"
          [ngClass]="selectedClass('my-cart')"
        ></span>
        <span *ngIf="!is('my-cart')" [ngClass]="fontClass('my-cart')">
          {{ "cart._" | translate }}
        </span>
        <div *ngIf="is('my-cart')" class="active--dot"></div>
      </div> -->
      <div
        *ngIf="moreButtonHidden"
        class="d-flex flex-column align-items-center side--menu-item"
        [ngClass]="is('more') ? 'selected' : ''"
        (click)="openModal(content)"
      >
        <span class="icon icon--more" [ngClass]="selectedClass('more')"></span>
        <span *ngIf="!is('more')" [ngClass]="fontClass('more')">
          {{ "common.more._" | translate }}
        </span>
        <div *ngIf="is('more')" class="active--dot"></div>
      </div>
    </div>
  </div>

  <ng-template #content let-modal>
    <div class="modal-body">
      <ul class="list-group">
        <li
          [routerLink]="['/feeds']"
          (click)="modal.close('Save click')"
          class="list-group-item"
        >
          <span
            class="d-block text-center icon mx-auto icon--my-feed"
            [ngClass]="selectedClass('feeds')"
          ></span>
          <span
            *ngIf="!is('feeds')"
            class="d-block text-center"
            [ngClass]="fontClass('feeds')"
            >{{ "common.feeds" | translate }}</span
          >
          <span
            *ngIf="is('feeds') && !showActive"
            class="d-block text-center"
            [ngClass]="fontClass('feeds')"
            >{{ "common.feeds" | translate }}</span
          >
          <div *ngIf="is('feeds')" class="active--dot"></div>
        </li>
        <li
          [routerLink]="['/calendar']"
          (click)="modal.close('Save click')"
          class="list-group-item"
        >
          <span
            class="d-block text-center icon mx-auto icon--calendar"
            [ngClass]="selectedClass('calendar')"
          ></span>
          <span
            *ngIf="!is('calendar')"
            class="d-block text-center"
            [ngClass]="fontClass('calendar')"
            >{{ "common.calendar" | translate }}</span
          >
          <span
            *ngIf="!is('calendar') && !showActive"
            class="d-block text-center"
            [ngClass]="fontClass('calendar')"
            >{{ "common.calendar" | translate }}</span
          >
          <div *ngIf="is('calendar')" class="active--dot"></div>
        </li>
        <li class="list-group-item">
          <span
            (click)="modal.close('Save click')"
            class="d-block text-center icon mx-auto icon icon--close"
          ></span>
        </li>
      </ul>
    </div>
  </ng-template>
</section>
